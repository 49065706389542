<template>
  <CRow class="justify-content-center">
    <Modal :modal="modal"></Modal>

    <!-- Content -->
    <CCol col="12">
      <CForm @submit.prevent="send">
        <CRow>
          <CCol lg="12">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <a href="javascript:void(0)" @click="$router.go(-1)"
                ><CIcon name="cil-arrow-left" class="mr-2" />Voltar</a
              >
            </div>
          </CCol>
        </CRow>
        <!-- Fields -->
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Papel</h4>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="6">
                <CInput
                  v-model="form.name"
                  label="Nome*"
                  placeholder="Nome do papel"
                  required
                />
              </CCol>
              <CCol lg="6">
                <CInput
                  v-model="form.description"
                  label="Descrição*"
                  placeholder="Descrição do papel"
                  required
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12" md="12" sm="12">
                <div class="form-group">
                  <label for="category">Permissões</label>
                  <Multiselect
                    id="category"
                    v-model="selectedPermissions"
                    :options="permissionsOptions"
                    :multiple="true"
                    group-values="permissions"
                    group-label="all"
                    :group-select="true"
                    :searchable="true"
                    :disabled="processing"
                    :required="true"
                  >
                    <span slot="noResult">Nenhum resultado encontrado</span>
                  </Multiselect>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <!-- Actions -->
        <div class="form-actions">
          <a href="javascript:void(0)" class="mr-3" @click="clearEdit"
            >Limpar alterações</a
          >
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
// components
import Modal from '@/components/ui/Modal'
import Multiselect from '@/components/ui/Multiselect'

// dependencies
import RoleService from '@/services/role.service'
import PermissionService from '@/services/permission.service'

import { cloneDeep } from 'lodash'

export default {
  metaInfo: {
    title: 'Papel',
    titleTemplate: 'Manager  - OW Interactive - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_permissions')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Modal,
    Multiselect
  },

  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      processing: false,
      query: {
        sorter: {}
      },
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      },
      permissionsOptions: [
        {
          all: 'Todos',
          permissions: []
        }
      ],
      role: {
        name: '',
        description: '',
        permissions: []
      },
      form: {},
      selectedPermissions: []
    }
  },

  watch: {
    selectedPermissions() {
      if (this.selectedPermissions.length > 0) {
        this.form.permissions = this.selectedPermissions
      } else {
        this.form.permissions = []
      }
    }
  },

  async created() {
    await this.getPermissions()
    if (this.$route.params.id) {
      await this.setRoleData()
    }
  },

  methods: {
    async getPermissions() {
      const response = await PermissionService.getAll()

      const permissions = response.map((permission) => {
        return {
          value: permission.id,
          label: permission.name
        }
      })

      this.permissionsOptions[0].permissions = [...permissions]
    },

    async setRoleData() {
      if (this.$route.params.id) {
        const response = await RoleService.getRoleInformation(
          this.$route.params.id
        )

        const permissions = []

        response.permissions.forEach((permission) => {
          const selected = this.permissionsOptions[0].permissions.find(
            (databasePermission) => databasePermission.value === permission.id
          )

          if (selected) {
            permissions.push(selected)
          }
        })

        this.role = {
          ...response,
          permissions
        }

        this.selectedPermissions = permissions
      }

      this.setFormData()
    },

    setFormData() {
      this.form = cloneDeep(this.role)
    },

    clearEdit() {
      this.setRoleData()
    },

    async send() {
      this.processing = true

      let response
      let message

      const data = {
        ...this.form,
        permissions: this.form.permissions.map((permission) => {
          return permission.value
        })
      }

      if (this.$route.params.id) {
        response = await RoleService.updateRole(this.$route.params.id, data)
        message = 'O papel foi atualizado com sucesso!'
      } else {
        response = await RoleService.storeRole(data)
        message = 'O papel foi cadastrado com sucesso!'
        this.new = false
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: true
        }

        setTimeout(() => {
          this.$router.push(
            this.$route.matched[this.$route.matched.length - 2].path
          )
        }, 3000)
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  }
}
</script>
